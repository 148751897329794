export function initTabs() {
    const headerSelectedClass = 'tab-header-selected';
    const paneSelectedClass = 'tab-pane-selected';

    $('.tabs').each(function () {
        const headers = $(this).find('.tab-header');

        headers.click(function () {
            const paneId = $(this).attr('aria-controls');
            const pane = $('#' + paneId);

            $(this).siblings()
                .removeClass(headerSelectedClass)
                .attr('aria-selected', 'false');
            $(this).addClass(headerSelectedClass)
                .attr('aria-selected', 'true');

            pane.siblings()
                .removeClass(paneSelectedClass);
            pane.addClass(paneSelectedClass);
        });

        // keyboard controls for accessibility
        headers.keydown(function (e) {
            let target;
            switch (e.code) {
                case 'ArrowUp':
                case 'ArrowRight':
                    target = $(this).next();
                    break;
                case 'ArrowDown':
                case 'ArrowLeft':
                    target = $(this).prev();
                    break;
            }

            if (target?.is('.tab-header')) {
                target.focus();
            }
        });
    });
}